import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Inner from '../../components/templates/Inner';
import { Divider, Box, Flex } from '../../components/atoms';
import FootNote from '../../components/molecules/FootNote';
import { designTips as nav } from '../../nav';
import img01 from '../../assets/img/design-tips/accessibility/01.png';
import img02 from '../../assets/img/design-tips/accessibility/02.png';
import img03 from '../../assets/img/design-tips/accessibility/03.png';
import img04 from '../../assets/img/design-tips/accessibility/04.png';
import img05 from '../../assets/img/design-tips/accessibility/05.png';
import img06 from '../../assets/img/design-tips/accessibility/06.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Inner nav={nav} title="Accessibility" mdxType="Inner">
      <h3>{`Accessibility`}</h3>
      <p>{`  Make the product suitable for all users, including those with limited possibilities.`}</p>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Keep minimal contrast ratio`}</h4>
      <p>{`  W3C recommends keeping the contrast ratio minimal.`}</p>
  <ol>
    <li>Big text at 3:1 (14px for bold, 18px and higher for regular)</li>
    <li>Small text at 4.5:1</li>
  </ol>
  <Flex my="xxl" mdxType="Flex">
    <Box width={320} mr={150} mdxType="Box">
      <Box mdxType="Box"><img src={img02} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Good color contrast.</Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img01} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Bad color contrast.</Box>
    </Box>
  </Flex>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Preserve consistent alignment`}</h4>
      <p>{`  Content consistency, alignment.`}</p>
  <Flex my="xxl" mdxType="Flex">
    <Box width={320} mr={150} mdxType="Box">
      <Box mdxType="Box"><img src={img04} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Show consistent information flow to the user. Preserve consistent alignment.</Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img03} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Сontrol elements mixed with content, paragraphs broken.</Box>
    </Box>
  </Flex>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Don’t mix`}</h4>
      <p>{`  Create readable content. Place control elements, so to make clear what they affect.`}</p>
  <Flex my="xxl" mdxType="Flex">
    <Box width={320} mr={150} mdxType="Box">
      <Box mdxType="Box"><img src={img06} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Group elements, build a clear hierarchy.</Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img05} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Hard to read data, no hierarchy.</Box>
    </Box>
  </Flex>
    </Inner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      